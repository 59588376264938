import { EventAvailableOutlined,  HomeOutlined,  PeopleOutline, PersonOutlined, WorkOutline} from "@mui/icons-material";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "../components/Header";

const RootComponent = () => {
    const [value, setValue] = React.useState(1);
    const navigate = useNavigate();
    return (<>
        <Header />

        <div style={{paddingTop: 125, marginBottom: 100}}>
          <Outlet />
          </div>
          
        <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              switch(newValue){
                case 0:
                  navigate('/prenotazioni');
                  break;
                case 1:
                  navigate('/');
                  break;
                case 2:
                  navigate('/servizi');
                  break;
                case 3:
                  navigate('/profilo')
                  break;
                default:
                  break;
              }
            }}
            style={{
              boxShadow: '1px 2px 9px rgba(0,0,0,.25)',
              position: 'fixed',
              bottom: 0,
              width: '100%',
              zIndex:99,
              paddingBottom: '15px'
            }}
          >
          <BottomNavigationAction label="Prenotazioni" icon={<EventAvailableOutlined fontSize={"large"} />} />
          <BottomNavigationAction label="Home" icon={<HomeOutlined fontSize={"large"} />} />
          {/* <BottomNavigationAction label="Home" icon={<HomeOutlined fontSize={"large"} />} /> */}
          <BottomNavigationAction label="Servizi" icon={<WorkOutline fontSize={"large"} />} />
          <BottomNavigationAction label="Profilo" icon={<PersonOutlined fontSize={"large"} />} />
        </BottomNavigation>
        </>)
}

export default RootComponent;