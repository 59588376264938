import { ArrowBackIos } from "@mui/icons-material"

export const Iframe = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('url');
   
    return (<div style={{height: '100vh', width: '100vw'}}>
                <div style={{display: 'flex', height: 50, alignItems: 'center'}}>
                    <div onClick={() => window.history.go(-1)}><ArrowBackIos></ArrowBackIos></div>
                </div>
                <iframe src={myParam} style={{height: '100%', width: '100%'}}></iframe>
             </div>)
}