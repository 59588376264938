import logo from './logo.svg';
import './App.css';
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import Header from './components/Header';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import NewspaperIcon from '@mui/icons-material/NewspaperRounded';
import HomeIcon from '@mui/icons-material/HomeRounded';
import DashboardIcon from '@mui/icons-material/DashboardCustomize';
import React, { useRef } from 'react';
import Dashboard from './pages/Dashboard';
import Certificati from './pages/Certificati';
import RootComponent from './layout/RootComponent';
import TrovaUnImbarco from './pages/TrovaUnImbarco';
import TuoiImbarchi from './pages/ITuoiImbarchi';
import Certificazioni from './pages/Certificazioni';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Profile from './pages/Profile';
import ForgotPassword from './pages/ForgotPassword';
import Servizi from './pages/Servizi';
import School from './pages/School';
import Notifications from './pages/Notifications';
import Prenotazioni from './pages/Prenotazioni';
import ChoosePath from './pages/ChoosePath';
import Community from './pages/Community';
import { Iframe } from './pages/Iframe';

function App() {
  
  const router = createBrowserRouter([
    {element: <Login />, path: '/login'},
    {element: <Signup />, path: '/signup'},
    {element: <ForgotPassword />, path: '/forgot'},
    {element: <Iframe />, path: '/iframe'},
    {
      path: "/",
      element: <RootComponent/>,
      children:[
        {element: <Dashboard />, path: "/"},
        {element: <Certificati />, path: '/certificati'},
        {element: <TrovaUnImbarco />, path: '/trova-un-imbarco'},
        {element: <TuoiImbarchi />, path: '/i-tuoi-imbarchi'},
        {element: <Certificazioni />, path: '/centri-di-formazione'},
        {element: <Profile />, path: '/profilo'},
        {element: <Servizi />, path:'/servizi'},
        {element: <School />, path: '/centro/:id'},
        {element: <Notifications />, path: '/notifications'},
        {element: <Prenotazioni />, path: '/prenotazioni'},
        {element: <ChoosePath />, path:'/onboarding'},
        {element: <Community />, path:'/community'}
      ],
    },

  ]);
  return (
    <div className="App">
        <RouterProvider router={router}>
            
        </RouterProvider>

    </div>
  );
}

export default App;
