import React, { useEffect, useRef, useState, useMemo } from 'react';
import Card from '../components/Card';
import { Add, DashboardCustomize, Language, Newspaper } from '@mui/icons-material';
import CustomButton from '../components/Button';
import Carousel from 'react-material-ui-carousel'
import FadedBackgroundDiv from '../components/FadedBackground';
import Imbarchi from '../components/Imbarchi';
import FindImbarchi from '../components/FindImbarchi';
import OverlayedBackgroundDiv from '../components/OverlayedBackground';
import { InputLabel, Fab, Menu, MenuItem, Modal, Box, Typography, TextField, Button, Select, FormControl, Checkbox } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import History from '../helpers/History';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ServerConfig } from '../connectors/Config';
import moment from 'moment';
import { config, S3} from 'aws-sdk';
import { Buffer } from "buffer";
import { AuthHelper } from '../helpers/AuthHelper';
import LoadingButton from '@mui/lab/LoadingButton';
import CardCertificati from '../components/CardCertificati';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Fireworks } from '@fireworks-js/react'
import Alert from '@mui/material/Alert';
import DownloadIcon from '@mui/icons-material/Download';
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import it from 'dayjs/locale/it';
import CardMaterial from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import haversine from 'haversine-distance';
import PdfViewer from '../components/PdfViewer'
import VisibilityIcon from '@mui/icons-material/Visibility';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {PaymentElement, useStripe, useElements} from '@stripe/react-stripe-js';
import Wave from '../components/WaveComponent.js';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

const style={
    container: {
        paddingLeft: 28, 
        paddingRight: 26,
    }
}
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85vw',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'scroll',
    maxHeight: '90vh',
    p: 3,
  };

const certificatesBST = [
    'af5b2947-8510-49a4-b8f4-b64359ddb975',
    'e28f429f-a579-429c-8b0b-8244b807271f',
    'b3200bf8-8863-4192-8ccb-700df8278d57',
    '4fe57438-4a04-4905-a800-3d14ec81bb52'
]
  
const fileConverterBase64 = (file) => {
    return new Promise((res, rej) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            res(reader.result)
        }
        reader.onerror = (err) => {
            rej(err)
        }
    })
}
const Dashboard = () => {
    const [items, setItems] = React.useState([
        {
            icon: <DashboardCustomize fontSize="large" />,
            text: 'Sopravvivenza & Salvataggio',
            widgets: [{title: 'Conseguito', description: '01/01/24'}, {title: 'Scade', description: '01/01/28'}]
        }
    ])
    const [centriFormazione, setCentriFormazione] = React.useState([
        {
            icon: <Language fontSize="large" />,
            text: 'Centro formazione',
            widgets: [{title: 'Città', description: 'Ravenna'}, {title: 'Distanza', description: '0.2km'}]
        },
        {
            icon: <Language fontSize="large" />,
            text: 'Centro formazione',
            widgets: [{title: 'Città', description: 'Roma'}, {title: 'Distanza', description: '102.5km'}]
        },
        {
            icon: <Language fontSize="large" />,
            text: 'Centro formazione',
            widgets: [{title: 'Città', description: 'Napoli'}, {title: 'Distanza', description: '302.0km'}]
        },
    ])
    const [posizione, setPosizione] = React.useState('');

    const handlePosizione = (
        event,
        newPosizione,
    ) => {
        setPosizione(newPosizione);
    };

    const NavigateSetter = () => {
        History.navigate = useNavigate();
        return null;
      }
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [modalImbarchi, setModalImbarchi] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [schools, setSchools] = React.useState([]);

    const [loading, setLoading] = React.useState(false);

    const [schoolSelectedCourse, setSchoolSelectedCourse] = React.useState({});

    const [bandiera, setBandiera] = React.useState('italiana');

    const handleChange = async (e) => {
        setLoading(true);

        config.update({
            Region: ServerConfig.AWS.s3Bucket.Region, 
            credentials: {
                accessKeyId: ServerConfig.AWS.s3Bucket.AccessKeyId,
                secretAccessKey: ServerConfig.AWS.s3Bucket.SecretKeyId
            }});
        const s3 = new S3({apiVersion: ServerConfig.AWS.s3Bucket.ApiVersion, credentials: {
            accessKeyId: ServerConfig.AWS.s3Bucket.AccessKeyId,
            secretAccessKey: ServerConfig.AWS.s3Bucket.SecretKeyId
        }});
        try{
            const fileBase64 = await fileConverterBase64(e.target.files[0]);
            const fileParticles = fileBase64.split(',')[0];
            const fileName = `${moment().valueOf()}.contract-file.${fileParticles.split(';')[0].split('/')[1]}`
            const params = {
                Bucket: ServerConfig.AWS.s3Bucket.BucketName,
                Key: fileName,
                Body: Buffer.from(fileBase64.split(',')[1], 'base64'),
                ContentType: e.target.files[0].type,
                ACL: 'public-read'
            };
            
            const res = await s3.putObject(params).promise();
            setCertificate({...certificate, pdf: `https://mase-storage.s3.eu-west-1.amazonaws.com/${fileName}`})
            setLoading(false);
        }catch(ex){
            console.log(ex);
            alert('Errore server')
        }
    };
    const hiddenFileInput = useRef(null);
    const handleClick = (event) => {
        hiddenFileInput.current?.click();
    };

    const [bst, setBST] = useState(false);
    const [certificate, setCertificate] = useState({
        number: "",
        release: dayjs(),
        expiry: "",
        pdf: "",
    })

    const [imbarco, setImbarco] = useState({
        name: "",
        type: "",
        grt: "",
        qualification: "",
        onboarded: "",
        offboarded: ""
    })
    const [certificates, setCertificates] = useState([]);
    const [imbarchi, setImbarchi] = useState([]);

    const [courses, setCourses] = useState([]);
    const [roles, setRoles] = useState([]);

    const [selectedCourse, setSelectedCourse] = useState("");

    /**
     * DA ISOLARE PER STRIPE IN UN COMPONENTE A PARTE
     */
    const stripePromise = loadStripe('pk_test_lVZtGpDyaeKuZ2I7z5MpDILw00jaFAxbxk')
    const [paymentIntent, setPaymentIntent] = useState(null);
    const [secretStripe, setSecretStripe] = useState(null);

    const [paymentEnabled, setPaymentEnabled] = useState(false);

    const [isProcessing, setIsProcessing] = useState(false);

    const [paymentStep, setPaymentStep] = useState(0);

    const [paymentMethod, setPaymentMethod] = useState("Carta di credito");

    
    const createPaymentIntent = async(amount, course) => {
        console.log('creating payment intent ', amount);
        setSchoolSelectedCourse({...course});
        if(paymentMethod === 'Carta di credito'){
            try{
                const res = await fetch('https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/stripe/intent', {
                    method: 'POST',
                    body: JSON.stringify({amount: amount * 100})
                })
                const json = await res.json();

                setPaymentIntent(json.id);
                setSecretStripe(json.client_secret);
                setPaymentStep(2);
            }catch(ex){
                alert("Errore server");
            }
        }else {
            window.setTimeout(async () => {
                const user = await AuthHelper.getUserFromIdToken();
                const res = await fetch('https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/prenotations', {
                    method: 'POST',
                    body: JSON.stringify({
                        profile: user.Username,
                        school: course.school_id,
                        partecipant: user.Username,
                        payment: "",
                        payment_status: 'ON_HOLD',
                        accepted: true,
                        course_id: certificateSelected,
                        course: course.id,
                        coursePayload: course,
                        method: paymentMethod
                    })
                });
                alert("Hai prenotato il corso.");
            }, 500);
           
        }
    }

    const CheckoutForm = () => {
        const stripe = useStripe();
        const elements = useElements();
        const ref = useRef();
        const [_loading, _setLoading] = useState(false);

        return (<form onSubmit={async (e) => {
            e.preventDefault(); 
            if(_loading){
                alert('Stiamo elaborando la richiesta precedente');
                return;
            }
            _setLoading(true);
            try{

                const user = await AuthHelper.getUserFromIdToken();
                const res = await fetch('https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/prenotations', {
                    method: 'POST',
                    body: JSON.stringify({
                        profile: user.Username,
                        school: schoolSelectedCourse.school_id,
                        partecipant: user.Username,
                        payment: paymentIntent,
                        payment_status: 'COMPLETED',
                        accepted: true,
                        course_id: certificateSelected,
                        course: schoolSelectedCourse.id,
                        coursePayload: schoolSelectedCourse
                    })
                })
                const {error} = await stripe.confirmPayment({
                    elements,
                    confirmParams: {
                        return_url: `${window.location.origin}`,
                    },
                })
                if(error){
                    if (error.type === "card_error" || error.type === "validation_error") {
                        alert(error.message)
                    } else {
                        console.log(error);
                        alert('Errore server');
                        
                    }
                    return;
                }
              
               }catch(ex){
                console.log(ex);
    
                alert('ciao');
               }
            
            
            
            // setIsProcessing(false);
            }}> 
            <PaymentElement></PaymentElement>
            <button type="submit" disabled={_loading}>{_loading ? 'Aspetta...' : 'Paga ora'}</button>
        </form>);
    }

    const StripeElement = () => {
        const options = {
            clientSecret: secretStripe
        }
        return <Elements stripe={stripePromise} options={options}>
            <CheckoutForm />
        </Elements> 
    }
    const loadExternalCourse = (id) => {
        setSelectedCourse(id);
        setCertificate({...certificate, course: id})
        setModalOpen(true);
    }
    const [prenotations, setPrenotations] = useState([]);

    const getUserPrenotations = async() => {
        const user = await AuthHelper.getUserFromIdToken();
        const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/prenotations/${user.Username}`);
        setPrenotations([...await res.json()]); 

        console.log('PRENOTAZIONI', prenotations);

    }
    const getCertificates = async() => {
        try{
            const user = await AuthHelper.getUserFromIdToken();
            const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/certificates?user=${user.Username}`);
            const certificates = [...await res.json()]
            setCertificates([...certificates]);
        }catch(ex){

        }
    }
    const loadCertificate = async() => {
        try{
            setLoading(true);
            const user = await AuthHelper.getUserFromIdToken();
            const ids = certificates?.map(x => x.course);
            console.log(ids);
            console.log('CHECK', ids.includes(certificate.course) && !certificate.id);
            if(ids.includes(certificate.course) && !certificate.id){
                alert("Il corso è già presente nel tuo profilo!");
                setLoading(false);
                return;
            }
               
            if(!moment(certificate.release).isValid() && !moment(certificate.expiry).isValid()){
                alert("Inserisci delle date valide!");
                setLoading(false);
                return;
            }
            if(moment(certificate.expiry).diff(moment()) < 0){
                alert("Non puoi caricare un certificato scaduto!");
                setLoading(false);
                return;
            }

            const res = await fetch('https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/certificates', {
                method: 'POST',
                body: JSON.stringify({...certificate, user: user.Username})
            })
            setLoading(false);
            window.location.reload();
            console.log(res);
        }catch(ex){

        }
    }
    const getImbarchi = async() => {
        try{
            const user = await AuthHelper.getUserFromIdToken();
            const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/imbarchi?user=${user.Username}`);
            setImbarchi([...await res.json()]);
        }catch(ex){

        }
    }

    const getSchools = async() => {
        try{
            const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/schools`);
            const json = await res.json();
            console.log('SCUOLA', json);
            
            setCentriFormazione([...json.map(x => ({
                icon: x.logo,
                text: x.name,
                id: x.id,
                indirizzo_scuola: x.indirizzo_scuola
            }))]);
            window.setTimeout(() => {console.log('SCUOLE', centriFormazione)}, 3000);

        }catch(ex){

        }
    }
    const getCourses = async() => {
        try{
            const res = await(await fetch('https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/certificates')).json();
            console.log(res);    
            setCourses([...res]);  
        }catch(ex){

        }
        
    }
    const loadImbarco = async() => {
        try{
            if(moment(imbarco.onboarded).valueOf() > moment(imbarco.offboarded)){
                alert("E' STATA INSERITA UNA DATA DI SBARCO ANTECEDENTE ALLA DATA DI IMBARCO");
                return;
            }
            if(imbarco.qualification === ""){
                alert("Bisogna inserire la qualifica");
                return;
            }
            setLoading(true);
            const user = await AuthHelper.getUserFromIdToken();
            const res = await fetch('https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/imbarchi', {
                method: 'POST',
                body: JSON.stringify({...imbarco, user: user.Username})
            })
            setLoading(false);
            if(res.status === 409){
                alert((await res.json()).message);
            }

            if(res.status === 200){
                window.location.reload();
            }
            console.log(res);
        }catch(ex){
            console.error('ERRORE', ex);
        }
        
    }

   
    const getRoles = async() => {
        try{
            const res = await fetch('https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/roles');
            const _roles = [...await res.json()];
       
            setRoles([..._roles]);
            sessionStorage.setItem('roles', JSON.stringify(_roles));
        }catch(ex){
            console.error('ERRORE', ex);
        }
        
    }
    const [role, setRole] = useState({});
    const [nextRole, setNextRole] = useState({});
    useEffect(() => {
        console.log('CHANGED', nextRole);
    }, [nextRole])
    const [showFireworks, setShowFireworks] = useState(false);

    const [certificateSelected, setCertificateSelected] = useState("");

    const [modalSchool, setModalSchool] = useState(false);

    const [countNotifications, setCountNotifications] = useState(0);

    const getNotifications = async() => {
        const _user = (await AuthHelper.getUserFromIdToken())?.UserAttributes;
        const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/notifications/count/${_user.filter(x => x.Name === 'sub')[0]?.Value}`)
        console.log('NOTIFICHE COUNT',await res.json());
    }
    const getNextRole = async() => {
        try{
            const _user = (await AuthHelper.getUserFromIdToken())?.UserAttributes;
            const role = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/role/${_user.filter(x => x.Name === 'sub')[0]?.Value}`);
            setRole({...await role.json()});
            const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/next-role/${_user.filter(x => x.Name === 'sub')[0]?.Value}`)
            const json = await res.json();
            console.log(json);
            setNextRole({...json});
        }catch(ex){

        }
       
    }
    function downloadPdf(pdfUrl) {
        fetch(pdfUrl)
          .then(response => response.blob())
          .then(blob => {
            // Create a blob URL for the PDF data
            var url = window.URL.createObjectURL(blob);
      
            // Create a link element to trigger the download
            var a = document.createElement("a");
            a.href = url;
            a.download = "downloaded.pdf"; // Set the desired file name
            document.body.appendChild(a);
      
            // Trigger a click event on the link element to initiate the download
            a.click();
      
            // Clean up by revoking the blob URL and removing the link element
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          })
          .catch(error => {
            console.error("Failed to download the PDF file: ", error);
          });
      }
    useEffect(() => {
        getCertificates();
        getImbarchi();
        getCourses();
        getRoles();
        getSchools();
        getUserPrenotations();
        getNotifications();
        window.setTimeout(() => {
            getNextRole();
        }, 500)
        window.document.querySelector('header').style.display = 'flex';
        window.setTimeout(() => {
            if(window.document.querySelector('#alert-role')){
                window.document.querySelector('#alert-role').style.top = '100px';
            }
        }, 500)
        function success(pos) {
            console.log(pos);
            const crd = pos.coords;
            setPosition({
                lat: crd.latitude,
                lng: crd.longitude
            });
        }
        function error(err) {
        return "Posizione sconosciuta"
        }
        navigator.geolocation.getCurrentPosition(success, error)
    }, [])

    const ref = useRef(null)

    const [position, setPosition] = useState({lat: 42.3522441, lng: 14.4028113});

    const latLng = {lat: 42.3522441, lng: 14.4028113};

    const [pdf, setPdf] = useState(null);

    const [schoolCourses, setSchoolCourses] = useState([]);

    const promote = async() => {
        localStorage.removeItem('examSeen');
        const _user = (await AuthHelper.getUserFromIdToken())?.UserAttributes;
        setShowFireworks(true);
        const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/promote/${_user.filter(x => x.Name === 'sub')[0]?.Value}`, {
            method: 'PUT'
        });
        if(res.status === 200){
            setTimeout(() => {
                window.location.reload();
            }, 4000);
        }
    }

    const findSchools = async(certificate) => {
        console.log('CERTIFICATO', certificate);
        setCertificateSelected(certificate);
        const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/courses/certificate/${certificate}`)
        const courses = await res.json();
        console.log(courses);
        setSchoolCourses([...courses.filter(x => moment(x.from).diff(moment()) > 0)]);
        setModalSchool(true);
    }
    const ships = [
        "NAVE PASSEGGERI",	"NAVE PETROLIERA",	"NAVE CHIMICHIERA",	"NAVE GASIERA",	"NAVE PORTA CONTENITORI",	"NAVE MERCE VARIA / BULK CARRIER",	"YACHTS COMMERCIALE", "YACHTS PRIVATI", "NAVI POLARI",	"NAVI DP",	"NAVI GNL", "PESCHERECCIO COSTIERO", "PESCHERECCIO D'ALTURA"
    ]

    const calculateDistance = (latLng) => {
        console.log(position, latLng);
        return (haversine(latLng, position) / 1000).toFixed(0);
    }
    const [showPdf, setShowPdf] = useState(false)
    const [showExpiry, setShowExpiry] = useState(true);
    useEffect(() => {
        const course = courses.filter(x => x.id === certificate.course)[0];
        if(course?.["validita'"] >= 99 && showExpiry){
            setShowExpiry(false);
        }else{
            setShowExpiry(true);
        }
    }, [certificate]);

    return (<div>
        <NavigateSetter />
        {pdf &&  <PdfViewer pdf={pdf ? pdf : null}
                    onCancel={()=>setShowPdf(false)}
                    visible={showPdf}
                    close={() => setShowPdf(false)}
            />}
       
        <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography variant="h6" component="h2">
                        Carica Corso
                    </Typography>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                        <input
                            type="file"
                            onChange={handleChange}
                            ref={hiddenFileInput}
                            style={{ display: "none" }} // Make the file input element invisible
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="id"
                            label="Numero"
                            name="id"
                            onChange={(e) => setCertificate({...certificate, number: e.target.value})}
                        />
                        <FormControl>  
                            <InputLabel id="demo-simple-select-helper-label">Tipologia certificato</InputLabel>

                            <Select
                                label="Tipologia certificato"
                                onChange={(e) => setCertificate({...certificate, course: e.target.value})}
                                value={certificate.course}
                            >
                                <MenuItem></MenuItem>
                                {courses?.sort().filter(course => course['nome corso'] !== 'Corso BST' && course['nome corso'] !== 'Refresh BST').map(course => (
                                    <MenuItem value={course['id']}>{course['nome corso']}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={it}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker label="Data di Rilascio" maxDate={dayjs()} onChange={(e) => setCertificate({...certificate, release: moment(e.toDate()).format('YYYY-MM-DD')})} format="DD/MM/YYYY"/>
                            </DemoContainer>
                        </LocalizationProvider>
                        
                        {showExpiry &&  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={it}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker label="Data di scadenza" minDate={dayjs(certificate.release).add(2, 'years')} maxDate={dayjs(certificate.release).add(5, 'years')} onChange={(e) => setCertificate({...certificate, expiry: moment(e.toDate()).format('YYYY-MM-DD')})} format="DD/MM/YYYY"/>
                            </DemoContainer>
                        </LocalizationProvider>}

                        <Button onClick={handleClick}>Carica il pdf</Button>

                        <LoadingButton variant="contained" onClick={() => loadCertificate()} loading={loading}>Carica</LoadingButton>

                    </Box>
                </Box>
        </Modal>
        <Modal
                open={modalImbarchi}
                onClose={() => setModalImbarchi(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography variant="h6" component="h2">
                        Carica Imbarco
                    </Typography>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                       
                        <TextField
                            margin="normal"
                            fullWidth
                            id="id"
                            label="Imbarcato su"
                            placeholder="Nome nave"
                            name="id"
                            onChange={(e) => setImbarco({...imbarco, name: e.target.value})}
                        />
                        <FormControl>  
                        <InputLabel id="demo-simple-select-helper-label">Tipologia nave</InputLabel>

                        <Select
                            label="Tipologia nave"
                            onChange={(e) => setImbarco({...imbarco, type: e.target.value})}
                        >
                            <MenuItem></MenuItem>
                            {ships.map(ship => (
                                <MenuItem value={ship}>{ship}</MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                        <TextField
                            margin="normal"
                            fullWidth
                            id="id"
                            label="G.R.T. o KW"
                            placeholder="Stazza lorda o potenza in tonnellate"
                            name="id"
                            onChange={(e) => setImbarco({...imbarco, grt: e.target.value})}
                        />
                        <FormControl>  
                        <InputLabel id="demo-simple-select-helper-label">Con la qualifica di</InputLabel>

                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            label="Con la qualifica di"
                            onChange={(e) => setImbarco({...imbarco, qualification: e.target.value})}
                        >
                            {roles?.filter(x => x.sotto_categoria === role.category).map(_role => {
                                return <MenuItem value={_role.id}>{_role.name}</MenuItem>
                            }
                            )}
                        </Select>
                        </FormControl>
                      
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={it}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker label="Imbarcato il" onChange={(e) => setImbarco({...imbarco, onboarded: moment(e.toDate()).format('YYYY-MM-DD')})} format="DD/MM/YYYY"/>
                            </DemoContainer>
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={it}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker label="Sbarcato il" onChange={(e) => setImbarco({...imbarco, offboarded: moment(e.toDate()).format('YYYY-MM-DD')})} format="DD/MM/YYYY"/>
                            </DemoContainer>
                        </LocalizationProvider>

                        <Button variant="contained" onClick={() => loadImbarco()}>Carica</Button>

                    </Box>
                </Box>
        </Modal>
        <Modal
                open={modalSchool}
                onClose={() => setModalSchool(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    {paymentStep === 0 && <>
                        <Typography variant="h6" component="h2" sx={{mb: 2}}>
                            Autocertifica la tua posizione
                        </Typography>
                        <ToggleButtonGroup
                            value={posizione}
                            exclusive
                            onChange={handlePosizione}
                            aria-label="text posizione"
                        >
                            <ToggleButton value="primo_imbarco" aria-label="left aligned">
                                Primo imbarco
                            </ToggleButton>
                            <ToggleButton value="sbarcato" aria-label="centered">
                                Sbarcato
                            </ToggleButton>
                            <ToggleButton value="imbarcato" aria-label="right aligned">
                                Imbarcato
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <div style={{marginTop: 6, marginBottom: 6}}>
                            <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">Bandiera Imbarco</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="italiana"
                                    name="radio-buttons-group"
                                    value={bandiera}
                                    onChange={(e) => setBandiera(e.target.value)}
                                >
                                    <FormControlLabel value="italiana" control={<Radio />} label="Bandiera Italiana" />
                                    <FormControlLabel value="estera" control={<Radio />} label="Bandiera Estera" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <ToggleButtonGroup
                        color="primary"
                        value={paymentMethod}
                        exclusive
                        onChange={(e) => {
                            setPaymentMethod(e.target.value);
                        }}
                        >
                        <ToggleButton value="Carta di credito">Carta di credito</ToggleButton>
                        <ToggleButton value="Bonifico">Bonifico</ToggleButton>
                        <ToggleButton value="In sede">In sede</ToggleButton>
                        </ToggleButtonGroup><br />
                        {certificatesBST.includes(certificateSelected) && <FormControlLabel disabled={bst} onChange={async (e, newValue) => {
                            setBST(newValue);
                            setCertificateSelected('d54ca14d-79b5-4c12-9cdf-3ba3c7ba06d0');
                            await findSchools('d54ca14d-79b5-4c12-9cdf-3ba3c7ba06d0');
                        }} value={bst} sx={{marginBottom: 6}} control={<Checkbox />} label="Prenota tutti i BST" />}
                     
                        <div style={{display: 'flex'}}>
                            <CustomButton text="Vai avanti" onClick={async () => {
                                try{
                                    if(!posizione || !bandiera || posizione === "" || bandiera === ""){
                                        alert("È obbligatorio specificare la posizione e la bandiera");
                                        return;
                                    }
                                    const user = await AuthHelper.getUserFromIdToken();
                                    const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/profile/documents/${user.Username}`);

                                    let documents = (await res.json()).map(x => x.name);
                                    
                                    const requiredDocuments = {
                                        primo_imbarco: [
                                            "Codice Fiscale",
                                            "Lettera d\'ingaggio",
                                            "Foglio provvisorio"
                                        ],
                                        sbarcato: [
                                            "Codice Fiscale",
                                            "Prima pagina libretto di navigazione",
                                        ],
                                        imbarcato: [
                                            "Codice Fiscale",
                                            "Lettera di licenza",
                                            "Prima pagina libretto di navigazione",
                                        ]
                                    }

                                    let nDocuments = 0;
                                    let documentsDone = []
                                    for(let document of documents){
                                        if(requiredDocuments[posizione].includes(document)){
                                            nDocuments += 1;
                                            documentsDone.push(document)
                                        }
                                    }
                                    
                                    if(requiredDocuments[posizione]?.length === nDocuments){
                                        if(bandiera === 'italiana' && posizione === 'sbarcato'){
                                            if(!documents.includes('Pagina libretto ultimo imb.')){
                                                alert("La pagina dell'ultimo imbarco è obbligatoria");
                                                return;
                                            }
                                        }
                                        if(bandiera === 'estera' && posizione === 'sbarcato'){
                                            if(!documents.includes('Discharge')){
                                                alert("La lettera di discharge è obbligatoria");
                                                return;
                                            }
                                        }
                                        if(documents.includes('Passaporto') || documents.includes("Carta d'identità")){
                                            setPaymentStep(1);
                                        }else{
                                            alert("Il passaporto o la carta d'identità è obbligatoria");
                                            return;
                                        }
                                    }else{
                                        alert(`Non hai i documenti obbligatori per iscriverti al corso: ${requiredDocuments[posizione].filter(document => !documentsDone.includes(document)).length === 1 ? 'Manca' : 'Mancano'} ${requiredDocuments[posizione].filter(document => !documentsDone.includes(document)).join(',')}`);
                                        return;
                                    }
                                }catch(ex){
                                    console.error('ERRORE', ex);
                                }
                            }}></CustomButton>
                        </div>
                        
                    </>}
                    {paymentStep === 1 && <>
                    <Typography variant="h6" component="h2" sx={{mb: 2}}>
                        Seleziona il centro di formazione
                    </Typography>
                    {schoolCourses.sort((a,b) => moment(a['from']).diff(moment(b['from']))).map(course => (
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                        <CardMaterial>
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    {course.school_name}
                                </Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    {course['nome corso']}
                                </Typography>
                                <Typography variant="body2">
                                    Da: {moment(course['from']).format('DD/MM/YYYY')} - A: {moment(course['to']).format('DD/MM/YYYY')}<br />
                                    {Number(course['price']).toLocaleString('it-IT', {style: 'currency', currency: 'EUR'})}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <LoadingButton size="small" variant="contained" onClick={() => createPaymentIntent(course['price'], course)}>Iscriviti ora</LoadingButton>
                            </CardActions>
                        </CardMaterial>
                        </Box>
                    ))}
                  
                    </>}
                    {paymentStep === 2 && <StripeElement />}
                </Box>
        </Modal>
        <div style={style.container}>

            {nextRole?.name && <>
                <Typography width={218} textTransform={'uppercase'} textAlign="center" justifyContent={"center"} margin="0 auto" color="#626262" fontFamily="Roboto">Scopri i requisiti per raggiungere il grado di</Typography>
                <Typography textTransform={'uppercase'} textAlign="center" justifyContent={"center"} margin="0 auto" marginTop="5px" color="#1B2B58" fontFamily="Spectral" fontSize="18px" fontWeight="bold">{nextRole.name}</Typography>
                {nextRole?.payloadTeacher && Object.keys(nextRole?.payloadTeacher).length > 0 &&
                <div style={{marginTop: 24}}><CustomButton text="Scopri requisiti per istruttore" onClick={() => {
                    console.log(nextRole.payloadTeacher);
                    setNextRole({...nextRole.payloadTeacher, ...nextRole.payloadTeacher.object, role_required: nextRole.payloadTeacher.role_required});
                }}></CustomButton></div>}

                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                {showFireworks && <Fireworks
                    ref={ref}
                    options={{ opacity: 1 }}
                    style={{
                    display: 'block',
                    zIndex: 999,
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    position: 'fixed',
                    background: 'rgba(0,0,0,1)'
                    }}
                />}
                {showFireworks && <div style={{zIndex: 999999, width: '80%', position: 'fixed'}}>
                    <Alert severity="success">Complimenti per essere passato al grado di <strong>{nextRole['name']}</strong>!</Alert>
                    </div>
                }
                
                <CardCertificati 
                fireworkRef={ref}
                icon={<div style={{width: 95, height: 95}}><CircularProgressbar strokeWidth={5} styles={buildStyles({
                    strokeLinecap: 'butt',
                    textSize: '31px',
                    pathTransitionDuration: 0.5,
                    pathColor: `#1B2B58`,
                    textColor: '#1B2B58',
                    trailColor: '#d6d6d6',
                    backgroundColor: '#3e98c7',
                })} value={nextRole.percentage === 100 ? 
                    nextRole?.remainingCourses?.length > 0 && nextRole.remainingMonths <= 0 ? 99 : 100
                    : nextRole.percentage
                } text={`${nextRole.percentage === 100 ? 
                    nextRole?.remainingCourses?.length > 0 && nextRole.remainingMonths <= 0 ? 99 : 100
                    : nextRole.percentage}%`}/></div>} 
                role={nextRole}
                buttonAction={() => promote()}
                durability={`${nextRole.remainingMonths}`}
                exam={nextRole.exam}
                url={`${nextRole.url}`}
                onFindSchool={findSchools}
                onExternalLoad={loadExternalCourse}
                info={nextRole?.informative}
                // teacher={nextRole.payloadTeacher && Object.keys(nextRole.payloadTeacher).length > 0 ? nextRole.payloadTeacher : false}
                description={nextRole?.role_required ? 
                nextRole?.tonnellata_necessaria === 'Yacht Commerciali' ? 
                    `Di navigazione di cui ${nextRole?.role_required ? parseInt(nextRole.numero_mesi_grado) - parseInt(nextRole?.grtMonths) < 0 ? 0 : parseInt(nextRole?.numero_mesi_grado) - parseInt(nextRole?.grtMonths) : ``} come ${nextRole?.role_required ? nextRole?.role_required?.name : null} su Yachts Commerciali`
                :
                    `Di navigazione di cui ${parseInt(nextRole?.numero_mesi_grado) - parseInt(nextRole?.grtMonths) < 0 ? 0 : parseInt(nextRole?.numero_mesi_grado) - parseInt(nextRole?.grtMonths)} come ${nextRole?.role_required ? nextRole.role_required.name : null} 
                    ${!isNaN(nextRole?.tonnellata_necessaria?.replaceAll('Kw', '').trim()) && nextRole?.tonnellata_necessaria !== 'Yacht Commerciali'  
                    ? 
                    !isNaN(Number(nextRole.tonnellata_necessaria.replaceAll('Kw'))) && Number(nextRole.tonnellata_necessaria.replaceAll('Kw')) < 3000
                    ? `su navi ${nextRole.tonnellata_necessaria.includes('Kw') ? 'con apparato motore pari o superiore a' : 'con stazza lorda pari o superiore a'} ${nextRole.tonnellata_necessaria} ${nextRole.tonnellata_necessaria.includes('Kw') ? 'Kw' : 'Grt'}`
                    : (`su navi ${nextRole.tonnellata_necessaria.includes('Kw') ? 'con apparato motore pari o superiore a' : 'con stazza lorda pari o superiore a'}
                        ${nextRole?.tonnellata_necessaria?.includes('Kw') ? nextRole?.tonnellata_necessaria : `${nextRole?.tonnellata_necessaria} tonnellate`}`) : ''}`
                :`Di navigazione come ${role?.role ? role.role : null}`}
                certificates={
                    nextRole?.remainingCourses?.map(x => ({name: x['nome corso'], registry: x['reg. stcw'], id: x['idCourse']}))
                }
                additional={nextRole?.additional}
                />
                
                </div>
            </>}
            <span style={{fontSize: '2rem', fontWeight: 600, color: 'rgba(6, 32, 105, 1)', marginTop: 24, marginBottom: 12, display: 'block'}}>I tuoi Corsi</span>
            <div className="carousel" style={{display: 'flex', width: '100%', overflow: 'auto', padding: 26, transform: 'translateX(-26px)'}}>{
                certificates?.length > 0 ? certificates?.sort((a,b) => a.expiry - b.expiry).map((item) => (<>
                    <Card item={item} expired={moment(item.expiry).diff(moment(), 'months') <= 5 && moment(item.expiry).diff(moment(), 'days') <= 0} prova='props' icon={<div style={{display: 'flex', gap: 3}}>
                        <VisibilityIcon fontSize="medium" onClick={() => {
                            // setPdf(item.pdf);
                            // window.scrollTo(0,0);
                            // setShowPdf(true);
                            window.location.href="/iframe?url="+item.pdf;
                        }}></VisibilityIcon> 
                        <DownloadIcon onClick={() => {
                            downloadPdf(item.pdf)
                            // link.dispatchEvent(new MouseEvent('click'));
                        }} fontSize="medium" /> 
                        <ShareIcon onClick={() => navigator.share({title: 'Voglio condividere il mio corso con te', text: `Visualizza il mio corso ${item.course['nome corso']} al link: ${item.pdf}`, url: item.pdf})} fontSize="medium" />
                        <DeleteIcon onClick={async () => {
                            const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/certificates/${item.id}`, {
                                method: 'DELETE'
                            })
                            if(res.status === 200){
                                alert("Corso eliminato con successo");
                                await getCertificates();
                            }
                        }} fontSize="medium" />
                        {moment(item.expiry).diff(moment(), 'months') <= 5 && moment(item.expiry).diff(moment(), 'days') > 0 && prenotations.filter(x => x.course_id_match === item.course.refresh).length > 0 && <Button variant="contained" size="small" disabled>Prenotato</Button>}
                        {moment(item.expiry).diff(moment(), 'months') <= 5 && moment(item.expiry).diff(moment(), 'days') > 0 && prenotations.filter(x => x.course_id_match === item.course.refresh).length === 0 && <Button variant="contained" size="small" onClick={() => {
                           
                            setCertificate({...certificate,
                                id: item.id,
                                course: item.course.id,
                                expiry: moment(item.expiry),

                            })
                            findSchools(item.course.refresh);

                            return;
                            setCertificate({...item});
                            
                        }} sx={{ml: 6, backgroundColor: '#ff0f0f'}}>Rinnova</Button>}
                        {moment(item.expiry).diff(moment(), 'months') <= 5 && moment(item.expiry).diff(moment(), 'days') <= 0 && prenotations.filter(x => x.course_id_match === item.course.id).length === 0 && <Button variant="contained" size="small" onClick={() => {
                            
                            setCertificate({...certificate,
                                id: item.id,
                                course: item.course.id,
                                expiry: moment(item.expiry)
                            })
                            findSchools(item.course.id);

                            return;
                            setCertificate({...item});
                            
                        }} sx={{ml: 6, backgroundColor: '#ff0f0f'}}>Rifai</Button>}
                        {moment(item.expiry).diff(moment(), 'months') <= 5 && moment(item.expiry).diff(moment(), 'days') <= 0 && prenotations.filter(x => x.course_id_match === item.course.id).length > 0 && <Button variant="contained" size="small" disabled onClick={() => {
                            
                            setCertificate({...certificate,
                                id: item.id,
                                course: item.course.id,
                                expiry: moment(item.expiry)
                            })
                            findSchools(item.course.id);

                            return;
                            setCertificate({...item});
                            
                        }} sx={{ml: 6, backgroundColor: '#ff0f0f'}}>Prenotato</Button>}
                        </div>} text={item.course['nome corso']} widgets={[{title: 'Conseguito', description: moment(item.release).format('DD/MM/YYYY')}, {title: 'Scade', description: moment(item.expiry).format('DD/MM/YYYY')}]} 
                        
                    />
                    </>
                )) : <div style={{width: '100%', textAlign: 'center'}}>
                    <p style={{textAlign: 'center', paddingBottom: 24, marginTop: 0}}>Non hai caricato nessun certificato. <br /> In questa sezione puoi caricare i tuoi certificati.</p>
                    <CustomButton text="Carica un corso" onClick={() => setModalOpen(true)} />
                    </div>
            }</div>
        </div>
        { certificates?.length > 0 && <CustomButton text="Scopri di più" onClick={() => navigate('/certificati')} />}
    
    <br />
    <br />
    {/* <div style={{position: 'relative'}}>
        <div className='wave'></div>
    </div> */}
    {/* <Wave fill='rgb(6, 32, 105)'
        paused={false}
        style={{ display: 'flex' }}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }}
  /> */}
    <FadedBackgroundDiv text={"I tuoi imbarchi"} action={() => navigate('/i-tuoi-imbarchi')} content={<Imbarchi imbarchi={imbarchi}/>} />
    <Wave fill='rgb(6, 32, 105)'
        paused={false}
        style={{ display: 'flex', transform: 'rotate(180deg)', position: 'relative', top: '-10px'}}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }}
  />
    <br />
    <br />
    <div style={style.container}>
        <span style={{fontSize: '2rem', fontWeight: 600, color: 'rgba(6, 32, 105, 1)', marginBottom: 12, display: 'block'}}>Centri di Formazione</span>
        <div className="carousel" style={{display: 'flex', width: '100%', overflow: 'auto', padding: 26, transform: 'translateX(-26px)'}}>{
            centriFormazione.length > 0 ? centriFormazione?.map((item) => (<>
                <Card onClick={() => window.location.href = `/centro/${item.id}`} ignoreDates icon={(<img src={`${item.icon}`} style={{width: 'auto', height: 50, objectFit: 'cover'}} />)} text={item.text} widgets={[{title: 'Indirizzo', description: item.indirizzo_scuola}]} />
                </>
            )) : null
        }</div>
    </div>
    <CustomButton text="Scopri di più" onClick={() => navigate('/centri-di-formazione')}/>
    <br />
    <br />
    <OverlayedBackgroundDiv text={"Trova un imbarco"} action={() => navigate('/trova-un-imbarco')} content={<FindImbarchi/>} source="/images/trova_imbarco.jpeg"/>
    <Wave fill='rgb(6, 32, 105)'
        paused={false}
        style={{ display: 'flex', transform: 'rotate(180deg)', position: 'relative', top: '-10px'}}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }}
  />
    <div><Menu
        id={'menu'}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setOpen(false)}
        sx={{
            bottom: 100
        }}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
    >
        <MenuItem onClick={() => setModalOpen(true)}>Carica Corso</MenuItem>
        <MenuItem onClick={() => setModalImbarchi(true)}>Carica Imbarco</MenuItem>
    </Menu>
    <Fab onClick={(e) => {setAnchorEl(e.currentTarget); setOpen(true)}} sx={{color: 'primary.main', background: '#fff'}} color="inherit" aria-label="add" style={{position: 'fixed', right: 12, bottom: '4.5rem'}}>
        <Add />
    </Fab></div>
    </div>
    
    
    )
    
}

export default Dashboard;