import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { AuthHelper } from '../helpers/AuthHelper';
import LoadingButton from '@mui/lab/LoadingButton';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';

import { Button, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        m-a-s-e.it
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.


export default function SignIn() {
  const [user, setUser] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  })
  const [loading, setLoading] = React.useState(false);
  const [regex, setRegex] = React.useState({
    // minimumCharacters: {
    //     regex: /^({8,})$/,
    //     validated: false
    // },
    number: {
        regex: /^(?=.*\d)/,
        validated: false
    },
    uppercase: {
        regex: /[A-Z]/,
        validated: false
    },
    specialCharacter: {
        regex: /(?=.*[@$!%*#?&])/,
        validated: false
    }
})
  const [step, setStep] = React.useState(0);

  const [code, setCode] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [cognitoUser, setCognitoUser] = React.useState(null);
  const signUp = async() => {
    setLoading(true);
    try{
      const res = await AuthHelper.signUp(user.email, user.password, user.first_name, user.last_name);
      console.log(res);
      setLoading(false);
      setCognitoUser(res.user);
      localStorage.setItem('user_bak', JSON.stringify(res.user));
      setStep(1);
    }catch(ex){
      setLoading(false);
      alert(ex.message);
      return;
    }
  }

  const confirmCode = async() => {
    setLoading(true);
    try{
      const res = await AuthHelper.confirmUser(cognitoUser, code);
      localStorage.clear();
      window.location.href="/login";
    }catch(ex){
      console.log(ex);
    }
  }

  React.useEffect(() => {
    if(localStorage.getItem('user_bak')){
      const session = JSON.parse(localStorage.getItem('user_bak'))
      setCognitoUser(new CognitoUser({
        Username: session.username,
        Pool: new CognitoUserPool({
          UserPoolId: session.pool.userPoolId,
          ClientId: session.pool.clientId,
          endpoint: session.pool.client.endpoint,
          AdvancedSecurityDataCollectionFlag: session.pool.advancedSecurityDataCollectionFlag
        })
      }));
      setStep(1);
    }
    
  }, [])
  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
        <img src="/images/New Logo MASE  TR2 mod.png" width={125} height={125} alt="logo"/>
          {step === 0 && (
            <>
             <Typography component="h1" variant="h5">
            Registrazione
          </Typography>
          <Box noValidate sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="first_name"
                  label="Nome"
                  name="first_name"
                  onChange={(e) => setUser({...user, first_name: e.target.value})}
                  autoFocus
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="last_name"
                  label="Cognome"
                  name="last_name"
                  onChange={(e) => setUser({...user, last_name: e.target.value})}
                />
              </Grid>
            </Grid>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              onChange={(e) => setUser({...user, email: e.target.value})}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              onChange={(e) => setUser({...user, password: e.target.value})}
              InputProps={{ // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <ul style={{listStyle: 'none', color: '#fff', textAlign: 'left', paddingLeft: 0}}>
                <li style={{color: regex.number.regex.test(user.password) ? '#097969' : '#ff0000'}}>{regex.number.regex.test(user.password) ? '✅' : '🚫'} Deve contenere almeno un numero</li>
                <li style={{color: regex.uppercase.regex.test(user.password) ? '#097969' : '#ff0000'}}>{regex.uppercase.regex.test(user.password) ? '✅' : '🚫'} Deve contenere almeno una lettera maiuscola</li>
                <li style={{color: regex.specialCharacter.regex.test(user.password) ? '#097969' : '#ff0000'}}>{regex.specialCharacter.regex.test(user.password) ? '✅' : '🚫'} Deve contenere almeno un carattere speciale</li>
                <li style={{color: user.password.length >= 8 ? '#097969' : '#ff0000'}}>{user.password.length >= 8 ? '✅' : '🚫'} Deve contenere almeno un carattere speciale</li>
            </ul>
            <FormControlLabel
            sx={{marginLeft: 0, textAlign: 'left'}}
            control={<Checkbox value="remember" color="primary" />}
              label={<p>Accetto i <a href="https://www.iubenda.com/termini-e-condizioni/54496977" target="_blank">termini e condizioni</a> dell'app M.A.S.E.</p>}
            />
            <FormControlLabel
            sx={{marginLeft: 0, textAlign: 'left'}}
            control={<Checkbox value="remember" color="primary" />}
            label={<p>Accetto la <a target="_blank" href="https://www.iubenda.com/privacy-policy/54496977">cookie e privacy policy</a> dell'app M.A.S.E.</p>}
            />
            {/* <FormControlLabel
            sx={{mt: 3}}
              control={<Checkbox value="remember" color="primary" />}
              label="Ricordami"
            /> */}
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => signUp()}
              loading={loading}
            >
              Registrati
            </LoadingButton>
            <Grid container>
              <Grid item xs={12}>
                <Link href="/login" variant="body2">
                  {"Sei già registrato? Accedi!"}
                </Link>
              </Grid>
            </Grid>
          </Box>
            </>
          )}
         {step === 1 && (
          <>
          <Typography component="h1" variant="h5">
         Conferma codice
       </Typography>
       <Box noValidate sx={{ mt: 1 }}>
         <Grid container spacing={2}>
           <Grid item xs={12}>
             <TextField
               margin="normal"
               required
               fullWidth
               id="first_name"
               label="Codice di conferma"
               name="confirmCode"
               onChange={(e) => setCode(e.target.value)}
               autoFocus
             />
           </Grid>
          
         </Grid>
       
         <LoadingButton
           type="submit"
           fullWidth
           variant="contained"
           sx={{ mt: 3, mb: 2 }}
           onClick={() => confirmCode()}
           loading={loading}
         >
           Conferma
         </LoadingButton>

         <Button onClick={() => {
          localStorage.removeItem('user_bak');
          window.location.href="/login"
         }}>Torna alla login</Button>
       </Box>
         </>
         )}
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
  );
}