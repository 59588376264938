import React from 'react';

const style = {
    card: {
        width: 250,
        backgroundColor: '#fff',
        color: '#1B2B58',
        boxShadow: '0px 0px 17px 0px rgba(0,0,0,0.25)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        textAlign: 'left',
        padding: 23,
        flexShrink: 0,
        marginLeft: 28,
        marginRight: 12,
        marginBottom: 28,
        flexGrow: 0
    },
    widget: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        color: '#555',
        width: '100%'
    },
    singleWidget: {
        flexDirection: 'column',
        display: 'flex'
    }
}
const CardImbarchi = (props) => {
    return (
    <div style={style.card}>
        <span style={{textTransform: 'uppercase', fontSize: 12, fontFamily: 'Roboto', marginBottom: 0}}>{props.name}</span>
        <div><h2 style={{fontSize: '24px', minHeight: 54, marginTop: 0, marginBottom: 0}}>{props.title}</h2></div>
        <div style={style.widget}>
            <div style={style.singleWidget}>
                <span style={{fontWeight: 'regular', textTransform: 'uppercase', fontFamily: "'Roboto', sans-serif", fontSize: '0.8rem', marginTop: 0, marginBottom: 0}}>
                    Imbarco
                </span>
                <span style={{fontWeight: 'bold', fontSize: '1.3rem'}}>
                    {props.shipmentStart}
                </span>
            </div>
            <div style={{height: '100%', width: 2, backgroundColor: '#eee'}}/>
            <div style={style.singleWidget}>
                <span style={{fontWeight: 'regular', textTransform: 'uppercase', fontFamily: "'Roboto', sans-serif", fontSize: '0.8rem'}}>
                    Sbarco
                </span>
                <span style={{fontWeight: 'bold', fontSize: '1.3rem'}}>
                    {props.shipmentEnd}
                </span>
            </div>
        </div>
        <div style={{height: 2, width: '100%', backgroundColor: '#eee'}}></div>
        <hr/>
        <span style={{textTransform: 'uppercase', fontSize: 12, fontFamily: 'Roboto'}}>Location</span>
        <div><h2 style={{fontSize: '21px', minHeight: 32, color: '#777', marginTop: 0, marginBottom: 0}}>{props.location}</h2></div>
        <div style={{height: 2, width: '100%', backgroundColor: '#eee'}}></div>
        <hr/>
        <span style={{textTransform: 'uppercase', fontSize: 12, fontFamily: 'Roboto'}}>RUOLO RICHIESTO</span>
        <div><h2 style={{fontSize: '18px', minHeight: 32, color: '#777', marginTop: 0, marginBottom: 0}}>{props.role}</h2></div>
    </div>);
}

export default CardImbarchi;