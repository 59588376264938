import * as React from 'react';
import { AuthHelper } from '../helpers/AuthHelper';
import 'typeface-titillium-web';

import '@dej611/spid-react-button/dist/index.css'
import { ServerConfig } from '../connectors/Config';
import { config, S3} from 'aws-sdk';
import moment from 'moment';
import { Alert, Modal, TextField, Box, Typography, ToggleButton, ButtonGroup, Link, ToggleButtonGroup, Stack, Select, MenuItem, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { ContentCopy, FileOpen, Flag } from '@mui/icons-material';
import { Buffer } from 'buffer';
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://m-a-s-e.it/">
        m-a-s-e.it
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70vw',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
function AlternateTimeline() {
    return (
      <Timeline>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="primary">
                <FormatListBulletedIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
                <h2 style={{marginTop: 0}}>Hai già individuato la tua Capitaneria?</h2>
                <p>Puoi richiedere l’iscrizione nelle matricole della Gente di Mare presso qualsiasi Capitaneria. Consulta l’elenco dei comandi territoriali e la Capitaneria di tuo interesse.</p>
                <Button variant="contained" onClick={() => window.location.href = "https://www.guardiacostiera.gov.it/organizzazione/comandi-territoriali"}>Trova la capitaneria</Button>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="primary">
                <FileOpen />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
                <h2 style={{marginTop: 0}}>Preparati a navigare</h2>
                <p>Per richiedere l’iscrizione è necessario superare un esame di idoneità fisica al nuoto e alla voga. Verifica con la tua capitaneria le date dei prossimi esami.</p>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="primary">
                <ContentCopy />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
                <h2 style={{marginTop: 0}}>Un ultimo esame prima di salpare</h2>
                <p>Recati presso l’USMAF (Ufficio di Sanità Marittima, Aerea e di Frontiera) più vicino per eseguire le verifiche mediche e ottenere il certificato di idoneità fisica. Verifica con la tua capitaneria l’elenco degli uffici medici disponibili.</p>
                <Button variant="contained" onClick={() => window.open("https://www.salute.gov.it/portale/usmafsasn/elencoUsmafSasn.jsp?lingua=italiano&area=usmaf-sasn&menu=vuoto")}>VERIFICA ELENCO IMO STCW</Button>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="primary">
                <Flag />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
                <h2 style={{marginTop: 0}}>Ci siamo quasi!</h2>
                <p>Una volta ottenuti i certificati di idoneità puoi procedere con la richiesta di iscrizione nelle matricole della Gente di Mare. <a href="https://lavoromarittimosdg.mit.gov.it/#/frontoffice/freshman-enrollment">Consulta l'elenco delle qualifiche</a> per verificare le condizioni che dovrai soddisfare per la tua qualifica e controlla i requisiti nella sezione dedicata di questo portale.</p>
                <Button variant="contained" onClick={() => window.open("https://lavoromarittimosdg.mit.gov.it/#/frontoffice/freshman-enrollment")}>VERIFICA I REQUISITI PER L'ISCRIZIONE</Button>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="primary">
                <Flag />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
                <h2 style={{marginTop: 0}}>Preparati all'imbarco</h2>
                <p>Una volta ottenuta una richiesta di imbarco da una compagnia o armatore, potrai eseguire la visita medica biennale e ottenere il Libretto di Navigazione per imbarcarti.</p>
                <Button variant="contained" onClick={() => window.open("https://lavoromarittimosdg.mit.gov.it/#/frontoffice/guide-to-seafarers")}>SCOPRI IL LIBRETTO DI NAVIGAZIONE</Button>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    );
  }
export default function ChoosePath() {
    const [open, setOpen] = React.useState(false);
    const [category, setCategory] = React.useState(null);
    const [roles, setRoles] = React.useState([]);
    const [selectedRole, setSelectedRole] = React.useState("");
    const [imo, setIMO] = React.useState("");
    const [matricola, setMatricola] = React.useState("");
    const [compartimento_marittimo, setCompartimento_marittimo] = React.useState("");
    const [showTimeline, setShowTimeline] = React.useState(false);

    const getRolesByCategory = async() => {
        const getRoles = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/roles/${category}`)
        setRoles([...await getRoles.json()])
    }
    React.useEffect(() => {
        if(category) getRolesByCategory();
        
    }, [category]);

    const [loading, setLoading] = React.useState(false);
    const fileConverterBase64 = (file) => {
        return new Promise((res, rej) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                res(reader.result)
            }
            reader.onerror = (err) => {
                rej(err)
            }
        })
    }
    const hiddenFileInput = React.useRef(null);
    const handleClick = (event) => {
        hiddenFileInput.current?.click();
    };
    const handleChange = async (e) => {
        setLoading(true);

        config.update({
            Region: ServerConfig.AWS.s3Bucket.Region, 
            credentials: {
                accessKeyId: ServerConfig.AWS.s3Bucket.AccessKeyId,
                secretAccessKey: ServerConfig.AWS.s3Bucket.SecretKeyId
            }});
        const s3 = new S3({apiVersion: ServerConfig.AWS.s3Bucket.ApiVersion, credentials: {
            accessKeyId: ServerConfig.AWS.s3Bucket.AccessKeyId,
            secretAccessKey: ServerConfig.AWS.s3Bucket.SecretKeyId
        }});
        try{
            const fileBase64 = await fileConverterBase64(e.target.files[0]);
            const fileParticles = fileBase64.split(',')[0];
            const fileName = `${moment().valueOf()}.imo.${fileParticles.split(';')[0].split('/')[1]}`
            const params = {
                Bucket: ServerConfig.AWS.s3Bucket.BucketName,
                Key: fileName,
                Body: Buffer.from(fileBase64.split(',')[1], 'base64'),
                ContentType: e.target.files[0].type,
                ACL: 'public-read'
            };
            
            const res = await s3.putObject(params).promise();
            setIMO(`https://mase-storage.s3.eu-west-1.amazonaws.com/${fileName}`);
            setLoading(false);
        }catch(ex){
            console.log(ex);
            alert('Errore server')
        }
    };
    const loadCertificate = async() => {
        if(!imo && roles.filter(x => x.id === selectedRole)[0]?.imo){
            alert("MANCA IL CERTIFICATO IMO");
            return;
        }
        const user = await AuthHelper.getUserFromIdToken();
        const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/initiate/${user.Username}`, {
            method: 'PUT',
            body: JSON.stringify({role: selectedRole, IMO: imo, compartimento_marittimo, matricola})
        })
        setLoading(false);
        window.location.href = '/';
        console.log(res);
    }
  return (
    <>
    <Modal
        open={open}
        sx={{width: '100vw'}}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
            <input
                type="file"
                onChange={handleChange}
                ref={hiddenFileInput}
                style={{ display: "none" }} // Make the file input element invisible
            />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Seleziona ora il tuo grado
          </Typography>
          <Stack>
            <Typography variant="caption" sx={{ mt: 2 }}>
                Seleziona categoria
            </Typography>
            <ToggleButtonGroup
                value={category}
                exclusive
                onChange={(e, nValue) => setCategory(nValue)}
                aria-label="text alignment"
                >
                <ToggleButton value="1" aria-label="left aligned">
                    1^
                </ToggleButton>
                <ToggleButton value="2" aria-label="centered">
                    2^
                </ToggleButton>
                <ToggleButton value="3" aria-label="right aligned">
                    3^
                </ToggleButton>
                {/**645dca28-6433-46bb-9767-6c86bf71dbba */}
            </ToggleButtonGroup>
            <Typography variant="caption" sx={{ mt: 2 }}>
                Seleziona ruolo
            </Typography>
            <Select onChange={(e, v) => setSelectedRole(e.target.value)} disabled={roles.length === 0}>
                {roles.sort((a, b) => a.name.localeCompare(b.name)).map((x) => (
                    <MenuItem value={x.id}>{x.name}</MenuItem>
                ))}
            </Select>
            
            <TextField
                            margin="normal"
                            fullWidth
                            id="id"
                            label="Matricola"
                            placeholder="Matricola"
                            name="id"
                            onChange={(e) => setMatricola(e.target.value)}
                        />
            <TextField
                            margin="normal"
                            fullWidth
                            id="id"
                            label="Compartimento marittimo"
                            placeholder="Compartimento marittimo"
                            name="id"
                            onChange={(e) => setCompartimento_marittimo(e.target.value)}
                        />
            {console.log(roles.filter(x => x.id === selectedRole))}
            {!roles.filter(x => x.id === selectedRole)[0]?.name?.includes('Allievo') && roles.filter(x => x.id === selectedRole)[0]?.imo && <Button onClick={handleClick} variant="contained" sx={{mt: 2}} color="primary">Carica il certificato IMO</Button>}
            <LoadingButton variant="contained" color="primary" onClick={() => loadCertificate()} sx={{mt: 2}} loading={loading}>Salva</LoadingButton>

          </Stack>
          
        </Box>
      </Modal>
      {!showTimeline &&<> <h1 style={{textAlign: 'left', paddingLeft: 24, paddingRight: 24, marginTop: 0}}>Sei già iscritto alla Gente di Mare?</h1>
        <ButtonGroup>
            <Button size='large' onClick={() => setOpen(true)}>Sì</Button>
            <Button size='large' onClick={() => setShowTimeline(true)}>No</Button>
        </ButtonGroup></>}
        {showTimeline && <><AlternateTimeline />
          <Button sx={{marginRight: 6}} onClick={() => {
            setShowTimeline(false);
        }} variant="contained" color="error">Torna indietro</Button>
        <Button onClick={() => {
            window.location.href="/"
        }} variant="contained" color="primary">Vai avanti</Button></>}
    </>
      
  );
}